<template>
  <div>

    <b-container fluid class="">
      <b-row>
        <b-col class="heatmap  " xl="9" lg="8" md="12" sm="12">
          <b-row class="pl-5 pr-5">
            <b-col class="video-heatmap">
              <div class="video-container pt-4 pl-4">
                <video-selector-v2 style="max-width: 720px;" v-if="isVideo" :activeVideo="this.getActiveVideo"
                  :videosUrls="this.videos" :setTime="this.hoverTime" :isPlaying="this.videoIsPlaying"
                  @videoTimeUpdate="videoTimeUpdate" @play="videoPlay" @pause="videoPause"></video-selector-v2>

                <image-selector v-if="isImage" :activeImage="this.getActiveImage" :imagesUrls="this.images">
                </image-selector>
              </div>
              <div class="toggle-container">
                <button v-if="videos.heatmap_path" class="no-decoration-btn toggle-btn"
                  :class="{ 'active-button': activeVideo === 'heatmap_path' }" @click="setVideo('heatmap_path')"><img
                    src="../assets/chart-icons/heatmap-icon.svg" alt="Heat"></button>
                <button v-if="videos.fogmap_path" class="no-decoration-btn toggle-btn"
                  :class="{ 'active-button': activeVideo === 'fogmap_path' }" @click="setVideo('fogmap_path')"><img
                    src="../assets/chart-icons/fogmap-icon.svg" alt="Fog"></button>
                <button v-if="videos.gazeplot_path" class="no-decoration-btn toggle-btn"
                  :class="{ 'active-button': activeVideo === 'gazeplot_path' }" @click="setVideo('gazeplot_path')"><img
                    src="../assets/chart-icons/gaze-icon.svg" alt="gaze"> </button>
                <button v-if="videos.resource_path" class="no-decoration-btn toggle-btn"
                  :class="{ 'active-button': activeVideo === 'resource_path' }" @click="setVideo('resource_path')"><img
                    src="../assets/viewer-icons/standard-viewer-mini-image.svg" alt="Norm"></button>
              </div>

            </b-col>
          </b-row>
          <b-row class="graph p-4" v-if="isVideo">
            <b-col>
              <x-y-area-graph :xyData="this.xyData" :currentTime="this.currentTime"
                @updateCurrentTime="updateTimeFromGraph"></x-y-area-graph>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="analytics  p-3 " xl="3" lg="4" md="12" sm="12">
          <heatmap-score :saliencyScores="saliencyScores" :emotionScores="emotionScores"
            :benchmark="benchmark"></heatmap-score>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
// import TitleInfo from "../components/Results/Score/TitleInfo.vue";
import { mapState } from "vuex";
import VideoSelectorV2 from "../components/Results/VideoSelectorV2.vue";
import HeatmapScore from "../components/Results/Heatmap/HeatmapScore.vue";
import XYAreaGraph from "../components/Results/Score/XYAreaGraph.vue";
import ImageSelector from "../components/Results/ImageSelector.vue";
export default {
  components: {
    // TitleInfo,
    VideoSelectorV2,
    HeatmapScore,
    XYAreaGraph,
    ImageSelector,
  },
  data() {
    return {
      heatmapVisible: false,
      heatmapVisibleValue: 0,
      activeVideo: 'heatmap_path',
      activeImage: 'heatmap_path',
      videoIsPlaying: false,
      currentTime: 0,
      hoverTime: 0
    };
  },
  methods: {
    setVideo(video) {
      this.activeVideo = video;
      this.activeImage = video;
      if (this.isVideo) {
        this.hoverTime = this.currentTime
      }
    },
    videoTimeUpdate(newTime, videoIndex) {
      this.currentTime = newTime
    },
    updateTimeFromGraph(newTime) {
      this.hoverTime = newTime
      this.videoPause()
    },
    videoPlay() {
      this.videoIsPlaying = true;
    },
    videoPause() {
      this.videoIsPlaying = false;
    }
  },
  computed: {
    ...mapState("analysisV2", ['analysisArray', 'guestMode']),
    videos() {
      const ret = {
        fogmap_path: this.analysisArray[0].fogmap_path,
        gazeplot_path: this.analysisArray[0].gazeplot_path,
        heatmap_path: this.analysisArray[0].heatmap_path,
        resource_path: this.analysisArray[0].resource_path,
      }
      return ret
    },
    images() {
      const ret = {
        fogmap_path: this.analysisArray[0].fogmap_path,
        gazeplot_path: this.analysisArray[0].gazeplot_path,
        heatmap_path: this.analysisArray[0].heatmap_path,
        resource_path: this.analysisArray[0].resource_path,
      }
      return ret
    },
    getActiveVideo() {
      return this.activeVideo
    },
    getActiveImage() {
      return this.activeImage
    },
    saliencyScores() {
      // maybe change it later with mathfloor
      const ret = {
        attentionScore: parseInt(this.analysisArray[0].saliency_scores.attention_score.toFixed()),
        focusScore: parseInt(this.analysisArray[0].saliency_scores.focus_score.toFixed()),
        spreadScore: parseInt(this.analysisArray[0].saliency_scores.spread_score.toFixed()),
        clarityScore: parseInt(this.analysisArray[0].saliency_scores.clarity_score.toFixed()),
        mentalDemand: parseInt(this.analysisArray[0].saliency_scores.mental_demand.toFixed()),

      }
      return ret
    },
    emotionScores() {
      const ret = this.analysisArray[0].emotion_data;
      return ret;
    },
    benchmark() {
      const ret = {
        attentionScore: parseInt(this.analysisArray[0].benchmark?.attention_score?.toFixed() || 0),
        focusScore: parseInt(this.analysisArray[0].benchmark?.focus_score?.toFixed() || 0),
        spreadScore: parseInt(this.analysisArray[0].benchmark?.spread_score?.toFixed() || 0),
        clarityScore: parseInt(this.analysisArray[0].benchmark?.clarity_score?.toFixed() || 0),
        mentalDemand: parseInt(this.analysisArray[0].benchmark?.mental_demand?.toFixed() || 0),
        engagement: parseInt(this.analysisArray[0].benchmark?.engagement?.toFixed() || 0),
        engagementScore: parseInt(this.analysisArray[0].benchmark?.emotion_score?.toFixed() || 0)
      }
      return ret
    },
    xyData() {
      const combinedVideoData = this.analysisArray[0].emotion_data.video_data.map((emotionData, index) => {
      const saliencyData = this.analysisArray[0].saliency_scores.video_data[index];

      const adjustedEmotionData = Object.fromEntries(
        Object.entries(emotionData).map(([key, value]) => [key, value * 100])
      );

        return { ...adjustedEmotionData, ...saliencyData };
      });

      return combinedVideoData;
    },

    isVideo() {
      return this.analysisArray[0].data_type === "video"
    },
    isImage() {
      return this.analysisArray[0].data_type === "image"
    }

  }
};
</script>

<style scoped>
.analytics,
.graph {
  background-color: #fff;

}

.bold {
  font-weight: bold;
}

.toggle-container {
  position: absolute;
  margin: 10px;
  top: 0;
  left: -40px;
  width: auto;
  padding: 8px;
  height: auto;
  background-color: #fff;
  border-radius: 5px;
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}



.graph {
  height: 900px;
}

.video-container {
  border-radius: 5px;
  min-width: 300px;
  max-width: 730px;
  position: relative;
  padding-left: 10px;
}

.video-heatmap {
  display: flex;
  justify-content: center;
  position: relative;
  height: 504px;
}

.toggle-btn {
  background-color: tr;
  width: 44px;
  height: 44px;
  margin-top: 5px;
  background-color: #fff;
  border-radius: 5px;
}

.active-button {
  background-color: #EDF0F7;
}
</style>
